import React from "react";
import style from "./style.module.css";
import Button from "./Button";
import { motion } from "framer-motion";

const Card = ({ title, description, icon, link, className, delay }) => {
  const initialState = {
    y: 80,
    opacity: 0,
  };
  const inView = {
    y: 0,
    opacity: 1,
  };
  const transit = {
    duration: 0.2,
    ease: "easeIn",
  };

  return (
    <motion.div
      initial={initialState}
      whileInView={inView}
      transition={{ ...transit, delay: delay }}
      viewport={{ once: true }}
      className={`${className} ${style.CardWarp}`}
      style={{ overflow: "hidden" }}
    >
      <div>
        <div className={style.icon}>
          <motion.img
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            src={icon}
            alt={title}
          />
        </div>
        <motion.h2
          initial={initialState}
          whileInView={inView}
          viewport={{ once: true }}
          transition={{ ...transit, delay: 0.1 }}
        >
          {title}
        </motion.h2>
        <ul>
          <motion.li
            initial={initialState}
            whileInView={inView}
            viewport={{ once: true }}
            transition={{ ...transit, delay: 0.15 }}
          >
            {description.li}
          </motion.li>
          <motion.li
            initial={initialState}
            whileInView={inView}
            viewport={{ once: true }}
            transition={{ ...transit, delay: 0.2 }}
          >
            {description.li2}
          </motion.li>
          <motion.li
            initial={initialState}
            whileInView={inView}
            viewport={{ once: true }}
            transition={{ ...transit, delay: 0.25 }}
          >
            {description.li3}
          </motion.li>
        </ul>
      </div>
      {/* <motion.div
        initial={initialState}
        whileInView={inView}
        viewport={{ once: true }}
        transition={{ ...transit, delay: 0.3 }}
      >
        <Button className={style.cardBtn} />
      </motion.div> */}
    </motion.div>
  );
};

export default Card;
