import React, { useEffect } from "react";
import sell1 from "../../assets/Aulani/Aulani 2024 1.jpg";
import sell2 from "../../assets/Aulani/Aulani 2024 2.jpg";
import sell3 from "../../assets/Aulani/Aulani 2024 3.jpg";
import sell4 from "../../assets/Aulani/Aulani 2024 4.jpg";
import sell5 from "../../assets/Aulani/Aulani 2024 5.jpg";
import sell6 from "../../assets/Aulani/Aulani 2024 6.jpg";
import sell7 from "../../assets/Aulani/Aulani 2024 7.jpg";
import sell8 from "../../assets/Aulani/Aulani 2024 8.jpg";
import style from "./style.module.css";
import Button from "../Elements/Button";
import { motion } from "framer-motion";

const SellEverrywhere = () => {
  useEffect(() => {
    setInterval(() => {
      document.querySelector("#images1").style.display = "none";
      document.querySelector("#images2").style.display = "block";
      document.querySelector("#images3").style.display = "none";
      document.querySelector("#images4").style.display = "none";
    }, 5000);
    setInterval(() => {
      document.querySelector("#images1").style.display = "none";
      document.querySelector("#images2").style.display = "none";
      document.querySelector("#images4").style.display = "none";
      document.querySelector("#images3").style.display = "block";
    }, 10000);
    setInterval(() => {
      document.querySelector("#images1").style.display = "block";
      document.querySelector("#images2").style.display = "none";
      document.querySelector("#images4").style.display = "none";
      document.querySelector("#images3").style.display = "none";
    }, 16000);
    setInterval(() => {
      document.querySelector("#images1").style.display = "none";
      document.querySelector("#images4").style.display = "block";
      document.querySelector("#images2").style.display = "none";
      document.querySelector("#images3").style.display = "none";
    }, 20000);
  }, []);

  const initialState = {
    y: 200,
    opacity: 0,
  };
  const inView = {
    y: 0,
    opacity: 1,
  };
  const transit = {
    duration: 0.7,
    ease: "easeIn",
  };

  return (
    <div className="Container">
      <div className={style.Sell}>
        <div id="images1" className={style.images}>
          <motion.img
            initial={{
              opacity: 0,
              x: -100,
              y: -10,
              rotate: 180,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              y: 0,
              rotate: 0,
            }}
            transition={{
              duration: 1,
              ease: "easeIn",
            }}
            src={sell1}
            alt=""
            className={style.img1}
          />
          <motion.img
            initial={{
              opacity: 0,
              x: 100,
              y: 10,
              rotate: 120,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              y: 0,
              rotate: 0,
            }}
            transition={{
              duration: 1,
              ease: "easeIn",
            }}
            src={sell2}
            alt=""
            className={style.img3}
          />
        </div>
        <div id="images2" className={`${style.images} ${style.images2}`}>
          <motion.img
            initial={{
              opacity: 0,
              y: -200,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.7,
              ease: "easeIn",
            }}
            src={sell3}
            alt=""
            className={style.img1}
          />
          <motion.img
            initial={{
              opacity: 0,
              y: 300,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.7,
              ease: "easeIn",
            }}
            src={sell4}
            alt=""
            className={style.img3}
          />
        </div>
        <div id="images3" className={`${style.images} ${style.images3}`}>
          <motion.img
            initial={{
              opacity: 0,
              x: 300,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.7,
              ease: "easeIn",
            }}
            src={sell5}
            alt=""
            className={style.img1}
          />
          <motion.img
            initial={{
              opacity: 0,
              x: -300,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.7,
              ease: "easeIn",
            }}
            src={sell6}
            alt=""
            className={style.img3}
          />
        </div>
        <div id="images4" className={`${style.images} ${style.images4}`}>
          <motion.img
            initial={{
              opacity: 0,
              x: 300,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.7,
              ease: "easeIn",
            }}
            src={sell7}
            alt=""
            className={style.img1}
          />
          <motion.img
            initial={{
              opacity: 0,
              x: -300,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.7,
              ease: "easeIn",
            }}
            src={sell8}
            alt=""
            className={style.img3}
          />
        </div>
        <motion.div
          initial={initialState}
          whileInView={inView}
          transition={{ ...transit }}
          viewport={{ once: true }}
          className={style.text}
        >
          <h1>Your Dream Vacation Awaits</h1>
          <p>Enjoy an amazing Disney vacation with your family. </p>
          <ul>
            <li>Save up to 70% off Disney rental rates</li>
            <li>No Room tax at most DVC Resorts</li>
            <li>Enjoy larger accomodatations.</li>
          </ul>
          {/* <Button /> */}
        </motion.div>
      </div>
    </div>
  );
};

export default SellEverrywhere;
