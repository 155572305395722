import Grow from "./components/Grow/index.jsx";
import Hero from "./components/Hero/index.jsx";
import Manage from "./components/Manage/index.jsx";
import Payments from "./components/Payments/index.jsx";
import SellEverrywhere from "./components/SellEverywhere/index.jsx";
import Cards from "./components/Cards/index.jsx";
import Process from "./components/Process/Process.jsx";

function App() {
  return (
    <main className="overflow-x-hidden">
      <Hero />
      <SellEverrywhere />
      <Process />
      <Grow />
      <Manage />
      {/* <Payments /> */}
      <Cards />
      {/* <div style={{marginTop:"100vh"}}></div> */}
    </main>
  );
}

export default App;
