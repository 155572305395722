import React from "react";
import style from "./style.module.css";
import c1 from "../../assets/card1.png";
import c2 from "../../assets/card2.png";
import c3 from "../../assets/card3.jpg";

import ButtonBg from "../Elements/ButtonBg";

import Card from "../Elements/Card";
import { motion } from "framer-motion";

const Cards = () => {
  return (
    <div className="Container">
      <div className={style.Cards}>
        <Card
          delay={0}
          className={style.card}
          title={"Existing Reservation"}
          description={{
            li: "Reservation cannot be modified",
            li2: "Renter may submit an offer to the member",
            li3: "Once accepted, the member will modify the names of the guest checking in.",
          }}
          icon={c1}
          link={"#"}
        />
        <Card
          delay={0.1}
          className={style.card}
          title={"Custom Reservation"}
          description={{
            li: "Renter posts their request (check-in date, resort, room-size, view)",
            li2: "Renter includes what they are willing to pay per point",
            li3: "The member will claim the request and book the reservation.",
          }}
          icon={c2}
          link={"#"}
        />
        <Card
          delay={0.23}
          className={style.card}
          title={"Transfer Points"}
          description={{
            li: "Renter must be a DVC Member",
            li2: "Member can only transfer current years points",
            li3: "Member can only transfer once per year.",
          }}
          icon={c3}
          link={"#"}
        />
      </div>
      <div className={style.titleWrap}>
        <motion.h1
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          Your Disney Vacation is Just a Few Clicks Away
        </motion.h1>
        <ButtonBg text={"Get Started for free"} className={style.btn} />
        <p>No credit card required — pay when you're ready.</p>
      </div>
    </div>
  );
};

export default Cards;
