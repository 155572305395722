import React from "react";
import style from "./style.module.css";
import {motion } from 'framer-motion';

const initialState = {
  y: 150,
  opacity: 0,
};
const inView = {
  y: 0,
  opacity: 1,
};
const transit = {
  duration: 0.3,
  ease: "easeIn",
};

const Process = () => {
  return (
    <div className="Container">
      <div className={style.Process}>
        <motion.div
        initial={initialState}
        whileInView={inView}
        transition={{...transit}}
        className={style.ProcessWrap}>
          <h2>How the Process Works for Renters</h2>
          <p className={style.subheading}>
            Begin your journey towards a magical vacation with three convenient
            options:
          </p>
          <ol>
            <li>
              <b>Purchase an Existing Reservation:</b> Your first option is to
              acquire an existing reservation, understanding that modifications
              are not possible. Simply visit the Listings page and select
              "Existing Reservation." If you find a suitable match, click the
              "Submit Offer" button, enter your desired price, and we'll present
              it to the member.
            </li>
            <li>
              <b>Create a Custom Request:</b> Alternatively, you can create a
              Custom Request from your profile page. We'll post your request and
              wait for a member who agrees to your terms to claim it and
              respond.
            </li>
            <li>
              <b>Point Transfer (For DVC Members):</b> DVC members can select
              the Point Transfer button to view a list of members and their
              desired price per point.
            </li>
          </ol>
          <p>
            Offers are presented in real-time via email and text messages. Once
            an agreement is reached, you'll receive notice to sign a rental
            agreement. Upon taking possession of the reservation, add it to your
            My Disney Experience account and submit full payment within 24
            hours. Your payment will be held in escrow until the check-in date,
            after which we'll release the funds to the member to complete the
            transaction.
          </p>
          <p>
            For point transfers, the receiving member must submit full payment
            to us. Upon confirmation you have received the points, we'll release
            the funds to the member.
          </p>
          <p>
            Embark on your dream vacation seamlessly with our hassle-free
            booking process!
          </p>
        </motion.div>
        <motion.div
         initial={initialState}
         whileInView={inView}
         transition={{...transit}}
        className={style.ProcessWrap}>
          <h2>How the Process Works for Members</h2>
          <p className={style.subheading}>
            Maximize the value of your Disney Vacation Club points by leveraging
            three convenient methods to earn rental income:
          </p>
          <ol>
            <li>
              <b>Rent or Sell an Existing Reservation:</b> Begin by creating an
              account and selecting the "Add Inventory to Rent" button. Provide
              the reservation details, and potential renters will submit offers
              in real-time.
            </li>
            <li>
              <b>Respond to Custom Requests::</b> Explore the Listings page and
              select "Custom Request." Identify a request you can fulfill at a
              reasonable price, then claim it. You'll have one hour to respond
              with a confirmation number or suggest an alternative for the
              renter's consideration.
            </li>
            <li>
              <b>Transfer Points:</b> Navigate to your profile page, choose
              "Inventory to Rent," and click on the "Point Transfer" button.
              Specify the number of current use year points you're willing to
              transfer and your asking price per point.
            </li>
          </ol>
          <p>
            Stay informed with real-time communication via email and text
            messages. Once an agreement is reached, you'll receive instructions
            to sign your rental agreement.
          </p>
          <p>
            Upon confirmation that the reservation is in the renter's
            possession, they must submit full payment within 24 hours. Funds for
            existing reservations and custom requests will be held until the
            check-in date, then released to you, deducting our service fee.
          </p>
          <p>
            For point transfers, the receiving member must submit full payment
            to us. Upon confirmation that the points have been received, we'll
            release the funds to you, deducting our service fee.
          </p>
          <p>
            Unlock the earning potential of your Disney Vacation Club points
            today!
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default Process;
