import React from "react";
import style from "./style.module.css";

const ButtonBg = ({ text, className, bg }) => {
  return (
    <a href="/" className={`${className} ${style.bgbtn}`} style={{background: 'bg'}}>
      {text}
    </a>
  );
};

export default ButtonBg;
