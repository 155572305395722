import React from "react";
import style from './style.module.css'

import arrow from "../../assets/arrow.svg";


const Button = ({className}) => {
  return (
    <a href="/" className={`${className} ${style.btn}`}>
      <span className={style.text}>Learn More </span>
      <span className={style.img}><img src={arrow} alt="" /></span>
    </a>
  );
};

export default Button;
