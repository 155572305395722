import React from "react";
import grow from "../../assets/dvc.mp4";
import style from "./style.module.css";
import Button from "../Elements/Button";

import { motion } from "framer-motion";

const Grow = () => {
  const initialState = {
    y: 200,
    opacity: 0,
  };
  const inView = {
    y: 0,
    opacity: 1,
  };
  const transit = {
    duration: 0.5,
    ease: "easeIn",
  };
  return (
    <div className="Container">
      <div className={style.Grow}>
        <motion.div
          initial={initialState}
          whileInView={inView}
          transition={{ ...transit }}
          viewport={{ once: true }}
          className={style.text}
        >
          <h1>We make it Easy</h1>
          <p>
            Take advantage of our automated system. Within a few minutes you can
            start planning your next Disney Vacation.
          </p>
          <ul>
            <li>Automated website</li>
            <li>Real-time updates</li>
            <li>E-sign capabilities</li>
          </ul>
          {/* <Button /> */}
        </motion.div>
        <div className={style.images}>
          <motion.video
            initial={{
              opacity: 0,
              x: 150,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.7,
              ease: "easeIn",
            }}
            viewport={{ once: true }}
            src={grow}
            autoPlay
            loop
            muted
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Grow;
