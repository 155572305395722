import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./style.css";
import phone from "../../assets/phone.png";
import tablet from "../../assets/tablet.png";
import { TypeAnimation } from "react-type-animation";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import logo from "../../assets/images/logo.png";
import ButtonBg from "../Elements/ButtonBg";

import phoneSlide1 from "../../assets/frames/mobile/mb1.jpg";
import phoneSlide2 from "../../assets/frames/mobile/mb2.jpg";
import phoneSlide3 from "../../assets/frames/mobile/mb3.png";
import phoneSlide4 from "../../assets/Aulani/Aulani 2024 4.jpg";
import phoneSlide5 from "../../assets/Aulani/Aulani 2024 5.jpg";
import phoneSlide6 from "../../assets/Aulani/Aulani 2024 6.jpg";
import phoneSlide7 from "../../assets/Aulani/Aulani 2024 7.jpg";

import tabletSlide2 from "../../assets/frames/tablet/tb2.jpg";
import tabletSlide1 from "../../assets/frames/tablet/tb1.png";
import tabletSlide3 from "../../assets/Aulani/Bay Lake Tower 2024 06.png";
import tabletSlide4 from "../../assets/Aulani/Bay Lake Tower 2024 07.png";
import tabletSlide5 from "../../assets/Aulani/Bay Lake Tower 2024 08.png";
import tabletSlide6 from "../../assets/Aulani/Bay Lake Tower 2024 09.png";
import tabletSlide7 from "../../assets/Aulani/Bay Lake Tower 2024 10.png";

import glasses from "../../assets/images/glasses.png";

import MobileWebsiteIllustration from "../../assets/images/Mobile_Website_illustration.png";
import { motion } from "framer-motion";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function Hero() {
  const HeroRef = useRef();
  const phoneFrameRef = useRef();
  const tabletFrameRef = useRef();
  const animFrameRef = useRef();
  const bgBoxRef = useRef();
  const bgBoxRef2 = useRef();
  const bgBoxRef3 = useRef();
  const lineRef = useRef();
  const phoneCoverRef = useRef();
  const glassesRef = useRef();
  const textDelay = 3200;
  const tabletCoverRef = useRef();

  const [currentColor, setCurrentColor] = useState(0);
  const [repeatCount, setRepeatCount] = useState(0);

  const colorArray = [
    "#fcfaf4",
    "#ff9661",
    "#fae053",
    "#6b8dd4",
    "#f7f2e0",
    "#8ac1b2",
  ];

  const nextColor = () => {
    setCurrentColor((prev) => (prev + 1) % 8);
  };

  useEffect(() => {
    gsap.to(phoneCoverRef.current, {
      x:
        phoneCoverRef.current.children[currentColor].offsetWidth * currentColor,
      duration: currentColor === 0 ? 0 : 0.7,
      ease: "sine",
    });
    gsap.to(tabletCoverRef.current, {
      x:
        tabletCoverRef.current.children[currentColor].offsetWidth *
        currentColor,
      duration: currentColor === 0 ? 0 : 0.7,
      ease: "sine",
    });
    gsap.to(
      [bgBoxRef.current, bgBoxRef2.current, lineRef.current, bgBoxRef3.current],
      {
        delay: 0.7,
        duration: currentColor === 0 ? 0 : 0.2,
        background: colorArray[currentColor - 1],
      }
    );
    gsap.to(".dynamicBg", {
      delay: 0.7,
      duration: currentColor === 0 ? 0 : 0.2,
      background: colorArray[currentColor - 1],
    });
    gsap.to(".textWhite", {
      delay: 0.7,
      duration: currentColor === 0 ? 0 : 0.2,
      color: "#fff",
    });
    gsap.to(".txtBlack", {
      delay: 0.7,
      duration: currentColor === 0 ? 0 : 0.2,
      color: "#000",
    });
    gsap.to(".txtW", {
      delay: 0.7,
      duration: currentColor === 0 ? 0 : 0.2,
      color: "#ddd",
    });
    gsap.to([".Hero .detail .underline *"], {
      delay: 0.7,
      duration: currentColor === 0 ? 0 : 0.3,
      textDecorationColor: colorArray[currentColor - 1],
    });
  }, [currentColor]);

  useLayoutEffect(() => {
    var tl = gsap.timeline();

    function isMobile() {
      return window.innerWidth < 600;
    }

    if (!isMobile()) {
      gsap.set(animFrameRef.current, {
        rotate: 30,
        yPercent: 50,
        xPercent: 0,
      });
      gsap.set(phoneFrameRef.current, {
        yPercent: -25,
      });
      gsap.set(
        [
          phoneFrameRef.current,
          tabletFrameRef.current,
          glassesRef.current,
          ".arrowDown",
        ],
        {
          opacity: 0,
        }
      );
      gsap.set(bgBoxRef.current, {
        xPercent: 100,
      });
      gsap.set(bgBoxRef2.current, {
        xPercent: -100,
      });

      tl.to(
        animFrameRef.current,
        {
          rotate: 0,
          yPercent: 100,
          xPercent: 5,
          ease: "none",
        },
        "a"
      )
        .to(
          phoneFrameRef.current,
          {
            yPercent: 5,
            ease: "none",
          },
          "a"
        )
        .fromTo(
          glassesRef.current.children,
          {
            opacity: 1,
          },
          {
            opacity: 0,
            duration: 0.1,
          },
          "a"
        )
        .fromTo(
          bgBoxRef.current,
          {
            yPercent: 0,
            xPercent: 0,
            ease: "none",
          },
          {
            yPercent: -65,
            xPercent: 20,
            ease: "none",
          },
          "a"
        )
        .fromTo(
          bgBoxRef2.current,
          {
            yPercent: 90,
            xPercent: 0,
            ease: "none",
          },
          {
            yPercent: -63,
            xPercent: 0,
            ease: "none",
          },
          "a"
        )
        .to(".arrowDown", {
          opacity: 0,
          width: 0,
        });

      ScrollTrigger.create({
        trigger: HeroRef.current,
        start: "top -10%",
        end: "95% bottom",
        scrub: true,
        pin: false,
        animation: tl,
      });
    }

    if (!isMobile()) {
      gsap.fromTo(
        ".Hero .Row .detail > div > *",
        {
          x: 300,
          opacity: 0,
        },
        {
          delay: 0.5,
          x: 0,
          opacity: 1,
          duration: 1,
          stagger: 0.1,
        }
      );
      gsap.to([phoneFrameRef.current, tabletFrameRef.current], {
        delay: 2,
        opacity: 1,
        stagger: 0.3,
      });
      gsap.to([glassesRef.current, ".arrowDown"], {
        delay: 2.5,
        opacity: 1,
      });
      gsap.fromTo(
        bgBoxRef.current,
        1,
        {
          xPercent: 100,
        },
        {
          delay: 2,
          xPercent: 0,
        }
      );
      gsap.fromTo(
        bgBoxRef2.current,
        1,
        {
          xPercent: 0,
        },
        {
          delay: 2,
          xPercent: 0,
        }
      );
    }

    if (isMobile()) {
      gsap.set(".fromLeft > *", {
        x: -300,
        opacity: 0,
      });
      gsap.set(".fromRight > *", {
        x: 300,
        opacity: 0,
      });

      gsap.fromTo(
        ".fromLeft > *",
        {
          x: -300,
          opacity: 0,
          duration: 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: 1,
          stagger: 0.05,
        }
      );
      gsap.fromTo(
        ".fromRight > *",
        {
          x: 300,
          duration: 0,
          opacity: 0,
        },
        {
          delay: 0.5,
          x: 0,
          opacity: 1,
          duration: 1,
          stagger: 0.05,
        }
      );
    }
  }, []);

  const initialState = {
    y: 200,
    opacity: 0,
  };
  const inView = {
    y: 0,
    opacity: 1,
  };
  const transit = {
    duration: 0.5,
    ease: "easeIn",
  };

  const btnTxtWhite = `${
    colorArray[currentColor - 1] === "#203864" ||
    colorArray[currentColor - 1] === "#6b8dd4" ||
    colorArray[currentColor - 1] === "#203864"
      ? "textWhite"
      : "txtBlack"
  }`;
  const txtWhite = `${
    colorArray[currentColor - 1] === "#203864" ||
    colorArray[currentColor - 1] === "#6b8dd4" ||
    colorArray[currentColor - 1] === "#203864"
      ? "txtW"
      : "txtBlack"
  }`;
  const imgWhite = `${
    colorArray[currentColor - 1] === "#203864" ||
    colorArray[currentColor - 1] === "#6b8dd4" ||
    colorArray[currentColor - 1] === "#203864"
      ? "imgWhite"
      : ""
  }`;

  return (
    <section ref={HeroRef} className="Hero">
      <div className="Container">
        <div className="Row desktop">
          <div className="detail">
            <h2 className="dvch2">DVC Rentals Done Right!</h2>
            <div className="firstDetail">
              <h1>
                {/* Start Planning <br /> */}
                <div className="underline">
                  <span className="text">
                    <TypeAnimation
                      sequence={[
                        "Start Planning your next Disney Vacation!",
                        textDelay + 500,
                        () => {
                          nextColor();
                        },
                        "Start Earning Rental Income!",
                        textDelay,
                        () => {
                          nextColor();
                        },
                        "Start Planning your next Disney Vacation!",
                        textDelay,
                        () => {
                          nextColor();
                        },
                        "Start Earning Rental Income!",
                        textDelay,
                        () => {
                          nextColor();
                        },
                      ]}
                      speed={30}
                      wrapper="span"
                      preRenderFirstString={true}
                      cursor={true}
                      repeat={999}
                      key={repeatCount}
                      style={{ display: "inline-block" }}
                    />
                  </span>
                  <div ref={lineRef} className="line"></div>
                </div>
              </h1>
              <ul>
                <motion.li
                  initial={initialState}
                  whileInView={inView}
                  viewport={{ once: true }}
                  transition={{ ...transit, delay: 0.5 }}
                >
                  <b>Members</b> pay only $4.00/point service fee
                </motion.li>
                <motion.li
                  initial={initialState}
                  whileInView={inView}
                  viewport={{ once: true }}
                  transition={{ ...transit, delay: 0.6 }}
                >
                  <b>Members:</b> Set your own rental rate
                </motion.li>
                <motion.li
                  initial={initialState}
                  whileInView={inView}
                  viewport={{ once: true }}
                  transition={{ ...transit, delay: 0.7 }}
                >
                  <b>Renters:</b> Offer what you want to pay
                </motion.li>
                <motion.li
                  initial={initialState}
                  whileInView={inView}
                  viewport={{ once: true }}
                  transition={{ ...transit, delay: 0.8 }}
                >
                  Subsidiary of DVC Sales
                </motion.li>
                <motion.li
                  initial={initialState}
                  viewport={{ once: true }}
                  whileInView={inView}
                  transition={{ ...transit, delay: 0.9 }}
                >
                  All Funds held in escrow until day of check-in
                </motion.li>
              </ul>
              <motion.div
                initial={{ ...initialState, y: 40 }}
                whileInView={inView}
                transition={{ ...transit, delay: 1 }}
                viewport={{ once: true }}
              >
                <ButtonBg
                  text={"Get Started for free"}
                  className={`dynamicBg ${btnTxtWhite}`}
                />
              </motion.div>
            </div>
            <div id="secondDetail">
              <h2 className={`${txtWhite}`}>
                Your first Disney Vacation Club stay is only a few clicks away.
              </h2>
              <p style={{ paddingRight: "0%" }} className={`${txtWhite}`}>
              We've created a solution for members to connect with families interested in renting DVC points. We are former Disney Vacation Club cast members who opened a DVC resale business in 2016. Since then, countless members have reached out interested in renting out their points. Likewise, we receive calls daily from non-members looking to rent points! If you are interested in either renting out your own points or renting a members points for your next Disney vacation, please visit the “How it Works” page for more details. Within a few minutes, you will be ready to post your points for rent, or place an offer. It's that easy!
                <span>
                  <a
                    className={`${txtWhite}`}
                    href="https://dvcrentalclub.com/how-it-works-renter"
                  >
                    How it works
                  </a>
                </span>{" "}
                for more details. Within few minutes, you will be ready to post
                your points for rent, or place an offer. It's that easy!
              </p>
              <div className="logo">
                <img src={logo} alt="" className={imgWhite} />
                {/* <div className="divider"></div>
                <p>
                  2024 TOP PERFORMER. <br />
                  Small Business eCommerce Software
                </p> */}
              </div>
              <ButtonBg text={"Get Started for free"} />
            </div>
          </div>
          <div ref={animFrameRef} className="anim-mocks">
            <div ref={bgBoxRef} className="bgBox"></div>
            <div ref={bgBoxRef2} className="bgBox2"></div>
            <div ref={glassesRef} className="glasses">
              <img src={glasses} alt="" />
            </div>
            <div ref={phoneFrameRef} className="phone-frame">
              <div className="phone-cover-wrapper">
                <div ref={phoneCoverRef} className="phone-cover">
                  <img src={phoneSlide1} alt="" />
                  <img src={phoneSlide7} alt="" />
                  <img src={phoneSlide6} alt="" />
                  <img src={phoneSlide5} alt="" />
                  <img src={phoneSlide4} alt="" />
                  <img src={phoneSlide3} alt="" />
                  <img src={phoneSlide2} alt="" />
                  <img src={phoneSlide1} alt="" />
                </div>
              </div>
              <img src={phone} alt="phone-mock" className="phone-mock" />
            </div>
            <div ref={tabletFrameRef} className="tablet-frame">
              <div className="tablet-cover-wrapper">
                <div ref={tabletCoverRef} className="tablet-cover">
                  <img src={tabletSlide1} alt="" />
                  <img src={tabletSlide7} alt="" />
                  <img src={tabletSlide6} alt="" />
                  <img src={tabletSlide5} alt="" />
                  <img src={tabletSlide4} alt="" />
                  <img src={tabletSlide2} alt="" />
                  <img src={tabletSlide3} alt="" />
                  <img src={tabletSlide1} alt="" />
                </div>
              </div>
              <img src={tablet} alt="tablet-mock" className="tablet-mock" />
            </div>
          </div>
        </div>
        <div className="detail Mob">
          <div className="fromLeft">
            <h2 className="dvch2">DVC Rentals Done Right!</h2>
            <h1>
              <div className="underline">
                <span className="text">
                  {" "}
                  <TypeAnimation
                    sequence={[
                      "Start Planning your next Disney Vacation!",
                      textDelay + 500,
                      () => {
                        nextColor();
                      },
                      "Start Earning Rental Income!",
                      textDelay,
                      () => {
                        nextColor();
                      },
                      "Start Planning your next Disney Vacation!",
                      textDelay,
                      () => {
                        nextColor();
                      },
                      "Start Earning Rental Income!",
                      textDelay,
                      () => {
                        nextColor();
                      },
                    ]}
                    speed={30}
                    wrapper="span"
                    preRenderFirstString={true}
                    cursor={true}
                    repeat={0}
                    style={{ display: "inline-block" }}
                  />
                </span>
              </div>
            </h1>
            <ul>
              <li>Sellers pay only $4.00/point service fee</li>
              <li>
                <b>Members:</b> Set your own rental rate
              </li>
              <li>
                <b>Renters:</b> Offer what you want to pay
              </li>
              <li>Subsidiary of DVC Sales</li>
              <li>All Funds held in escrow until day of check-in</li>
            </ul>
            <ButtonBg
              text={"Get Started for free"}
              className={`dynamicBg ${btnTxtWhite}`}
            />{" "}
          </div>
          <div className="fromRight">
            <div className="imgCon">
              <img src={MobileWebsiteIllustration} alt="" />
            </div>
            <h2 className={`${txtWhite}`}>
              Your first Disney Vacation Club stay is only a few clicks away.
            </h2>
            <p className={`${txtWhite}`}>
              We've created a solution for members to connect with families
              interested in renting DVC points. We are former Disney Vacation
              Club cast members who opened a DVC resale business in 2016. Since
              then, countless members have reached out interested in renting out
              their points. Likewise, we receive calls daily from non-members
              looking to rent points! If you are interested in either renting
              out your points or renting a members points for your next Disney
              vacation, please read the{" "}
              <span>
                <a
                  className={`${txtWhite}`}
                  href="https://dvcrentalclub.com/how-it-works-renter"
                >
                  How it works
                </a>
              </span>{" "}
              for more details. Within few minutes, you will be ready to post
              your points for rent, or place an offer. It's that easy!
            </p>
          </div>
          <div ref={bgBoxRef3} className="bgBox3"></div>
        </div>
      </div>
      <div className="arrowDown">
        <ScrollLink
          activeClass="active"
          to={"secondDetail"}
          spy={true}
          smooth={true}
          offset={20}
          duration={500}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <title>Chevron</title>
            <polyline
              fill="none"
              stroke="#3A4A59"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              points=".222 4.222 15.778 4.222 15.778 19.778"
              transform="rotate(45 8 12)"
            ></polyline>
          </svg>
        </ScrollLink>
      </div>
    </section>
  );
}
