import React, { useEffect } from "react";
import manage1 from "../../assets/mb1.png";
import manage2 from "../../assets/mb2.png";
import manage3 from "../../assets/mb3.png";
import manage4 from "../../assets/mb4.png";
import manage5 from "../../assets/mb5.png";
import style from "./style.module.css";
import Button from "../Elements/Button";
import appStore from "../../assets/app_store.svg";
import googleStore from "../../assets/google_play.svg";

import { motion } from "framer-motion";

const Manage = () => {
  useEffect(() => {
    setInterval(() => {
      document.querySelector("#manage1").style.display = "none";
      document.querySelector("#manage2").style.display = "block";
    }, 4000);
    setInterval(() => {
      document.querySelector("#manage2").style.display = "none";
      document.querySelector("#manage1").style.display = "block";
    }, 8000);
  }, []);

  const initialState = {
    y: 200,
    opacity: 0,
  };
  const inView = {
    y: 0,
    opacity: 1,
  };
  const transit = {
    duration: 0.5,
    ease: "easeIn",
  };

  return (
    <div className="Container">
      <div className={style.Manage}>
        <div className={style.images}>
          <div id={"manage1"} className={style.manage1}>
            <motion.img
              initial={{
                opacity: 0,
                x: 300,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              transition={{
                duration: 0.7,
                ease: "easeIn",
              }}
              src={manage1}
              alt=""
              className=""
            />
            <motion.img
              initial={{
                opacity: 0,
                x: -300,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              transition={{
                duration: 0.7,
                ease: "easeIn",
              }}
              src={manage2}
              alt=""
              className=""
            />
            <motion.img
              initial={{
                opacity: 0,
                x: 300,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              transition={{
                duration: 0.45,
                delay: 0.6,
                ease: "easeIn",
              }}
              src={manage3}
              alt=""
              className=""
            />
          </div>
          <div id="manage2" className={style.manage2}>
            <motion.img
              initial={{
                opacity: 0,
                y: 300,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                duration: 0.7,
                ease: "easeIn",
              }}
              src={manage4}
              alt=""
              className=""
            />
            <motion.img
              initial={{
                opacity: 0,
                y: -300,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                duration: 0.7,
                ease: "easeIn",
              }}
              src={manage5}
              alt=""
              className=""
            />
            <motion.img
              initial={{
                opacity: 0,
                x: -300,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              transition={{
                duration: 0.45,
                delay: 0.6,
                ease: "easeIn",
              }}
              src={manage3}
              alt=""
              className=""
            />
          </div>
        </div>
        <motion.div
          initial={initialState}
          whileInView={inView}
          transition={{ ...transit }}
          className={style.text}
        >
          <h1>Take us with you</h1>
          <p>Download our app and stay connected when you are on the go.</p>
          <ul>
            <li>
              <b>Streamlined Communication:</b> We offer direct channels for
              communication with customer support, or other users, fostering
              quicker responses and interactions.
            </li>
            <li>
              <b>Convenience:</b> Access services, information, or entertainment
              anytime, anywhere, directly from your smartphone or tablet.
            </li>
            <li>
              <b>Enhanced Functionality:</b> Enjoy features tailored
              specifically for mobile devices, offering a seamless and optimized
              user experience.
            </li>
            <li>
              <b>Personalization:</b> Customize your app experience based on
              your preferences, behavior, or location, providing a more tailored
              and relevant interaction.
            </li>
            <li>
              <b>Push Notifications:</b> Stay updated with real-time alerts,
              reminders, or personalized messages, keeping you informed and
              engaged with the app's content or offerings.
            </li>
          </ul>
          <p>
            Download Our <b>Mobile App</b> for better user experience.
          </p>
          <div className={style.logoText}>
            <a href="/">
              <div className={style.img}>
                <img src={appStore} alt="" />
              </div>
            </a>
            <a href="/">
              <div className={style.img}>
                <img src={googleStore} alt="" />
              </div>
            </a>
          </div>
          {/* <Button /> */}
        </motion.div>
      </div>
    </div>
  );
};

export default Manage;
